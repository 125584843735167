$course-content-background-color: white !default;
$course-sidebar-background-color: white !default;
$course-header-background-color: white !default;
$course-header-color: #212529 !default;

$course-primary-background-color: #ffb126 !default;
$course-primary-background-color-hover: darken($course-primary-background-color, 10%) !default;
$course-primary-color: white !default;
$course-border-color: #e3ebf6 !default;
$course-chapter-background-color: #f5f8fa !default;
$course-chapter-color: #212529 !default;
$course-lesson-color: #95aac9 !default;
$course-lesson-hover-background-color: #f5f8fa !default;
$course-lesson-hover-color: #6e84a3 !default;
$course-lesson-complete-color: #198754 !default;

$course-header-height: 70px !default;
$course-sidebar-width: 350px !default;

$course-enable-horizontal-safe-area: false !default;

@function get-safe-area-inset-left() {
  @if $course-enable-horizontal-safe-area {
    @return env(safe-area-inset-left);
  } @else {
    @return 0px;
  }
}
@function get-safe-area-inset-right() {
  @if $course-enable-horizontal-safe-area {
      @return env(safe-area-inset-right);
  } @else {
    @return 0px;
  }
}

@if variable-exists(primary) {
  $course-primary-background-color: $primary;
}

body,
html {
  &.disable-scroll {
    height: auto !important;
    overflow: hidden !important;
  }
}

.shopcrate-course-fullscreen {
  --sc-course-content-background-color: #{$course-content-background-color};
  --sc-course-sidebar-background-color: #{$course-sidebar-background-color};
  --sc-course-header-background-color: #{$course-header-background-color};
  --sc-course-header-color: #{$course-header-color};

  --sc-course-primary-background-color: #{$course-primary-background-color};
  --sc-course-primary-background-color-hover: #{$course-primary-background-color-hover};
  --sc-course-primary-color: #{$course-primary-color};
  --sc-course-border-color: #{$course-border-color};
  --sc-course-chapter-background-color: #{$course-chapter-background-color};
  --sc-course-chapter-color: #{$course-chapter-color};
  --sc-course-lesson-color: #{$course-lesson-color};
  --sc-course-lesson-hover-background-color: #{$course-lesson-hover-background-color};
  --sc-course-lesson-hover-color: #{$course-lesson-hover-color};
  --sc-course-lesson-complete-color: #{$course-lesson-complete-color};

  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  min-height: max(100vh, 100%);
  min-height: -webkit-fill-available;

  background: var(--sc-course-content-background-color);

  .course-header {
    position: fixed;
    top: 0;
    background: var(--sc-course-header-background-color);
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1rem;
    padding-right: calc(1.5rem + get-safe-area-inset-right());
    padding-left: calc(1.5rem + get-safe-area-inset-right());
    padding-bottom: 1rem;
    border-bottom: var(--sc-course-border-color) 1px solid;

    height: $course-header-height;

    .course-title {
      font-size: 1.5rem;
      font-weight: 500;
      flex-grow: 1;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      color: var(--sc-course-header-color);
    }
  }
  .course-container {
    flex-grow: 1;
    display: flex;
    min-height: 0;
    flex-direction: column;

    .course-sidebar {
      position: fixed;
      top: $course-header-height;
      left: 0;
      flex-shrink: 0;
      width: $course-sidebar-width;
      height: 100%;
      min-height: 0;
      border-right: var(--sc-course-border-color) 1px solid;

      background-color: var(--sc-course-sidebar-background-color);

      overflow-y: auto;
      overscroll-behavior-y: contain;

      padding-bottom: env(safe-area-inset-bottom);

      .course-sidebar-header {
        display: flex;
        flex-direction: column;
        padding: 1rem 0.5rem 1rem calc(1rem + get-safe-area-inset-left());
        border-bottom: var(--sc-course-border-color) 1px solid;
        .course-title {
          font-size: 1.3rem;
          font-weight: 500;
          flex-grow: 1;
          padding: 0 0.5rem;
        }
        button {
          padding: 0 0.5rem;
          margin-top: 0.5rem;
          width: 100%;
          text-align: left;
        }
      }
    }
    .course-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      min-height: 0;

      padding-left: get-safe-area-inset-left();
      padding-right: get-safe-area-inset-right();

      // Add margin so content doesn't start behind header.
      margin-top: $course-header-height;
      // Add margin so content doesn't flow behind sidebar.
      margin-left: $course-sidebar-width;

      .course-lesson-content {
        flex-grow: 1;
        padding: 2rem;
        .lesson-title {
          border-bottom: var(--sc-course-border-color) 1px solid;
          padding-bottom: 1.5rem;
          margin-bottom: 1.5rem;
          .pretitle {
            font-size: 0.625rem;
            font-weight: 501;
            text-transform: uppercase;
            letter-spacing: 0.08em;
            color: #95aac9;
            margin: 0;
          }
          .title {
            font-size: 1.75rem;
            font-weight: 501;
            margin: 0;
            line-height: 1.2;
          }
        }

        a {
          color: var(--sc-course-primary-background-color);
        }
      }

      //noinspection CssReplaceWithShorthandSafely
      .course-content-footer {
        border-top: var(--sc-course-border-color) 1px solid;
        padding-top: 1.2rem;
        padding-right: 2rem;
        padding-left: 2rem;
        padding-bottom: calc(1.2rem + env(safe-area-inset-bottom));
      }

      .lesson-completion-badge {
        background-color: var(--sc-course-lesson-complete-color);
        color: white;
        border-radius: 3rem;
        padding: 0.25rem 0.6rem;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  .btn.btn-primary {
    background-color: var(--sc-course-primary-background-color);
    color: var(--sc-course-primary-color);
    border-color: var(--sc-course-primary-background-color);
    &:hover {
      background-color: var(--sc-course-primary-background-color-hover);
      border-color: var(--sc-course-primary-background-color-hover);
    }
  }
}

.shopcrate-course-fullscreen.mobile {
  .course-header {
    padding-right: calc(1rem + get-safe-area-inset-right());
    padding-left: calc(1rem + get-safe-area-inset-left());

    .course-title {
      font-size: 1.1rem;
    }
  }
  .course-container {
    flex-direction: column;
    .course-sidebar {
      top: 0;

      width: calc(
              min(100% - 45px, $course-sidebar-width)
              + get-safe-area-inset-left()
      );
      height: 100vh;

      border-right: none;
      border-bottom: var(--sc-course-border-color) 1px solid;
      z-index: 1052;

      transition: transform .2s ease-in-out,
          box-shadow .2s ease-in-out;

      transform: translateX(-100%);
    }
    .course-content {
      // Remove margin that was added in non-mobile so content doesn't flow behind sidebar.
      margin-left: 0;
      .course-lesson-content {
        padding: 2rem 1rem;
      }
      .course-content-footer {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
  }
  .course-mobile-content-blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1051;

    transition: opacity .2s ease-in-out;

    pointer-events: none;
    opacity: 0;
  }
  &.sidebar-open {
    .course-sidebar {
      transform: translateX(0);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    .course-mobile-content-blur {
      pointer-events: unset;
      opacity: 1;
    }
  }
}

.course-sidebar {
  .course-sidebar-chapter {
    border-bottom: var(--sc-course-border-color) 1px solid;

    .chapter-title {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0.75rem 1.5rem 0.75rem calc(1.5rem + get-safe-area-inset-left());
      background-color: var(--sc-course-chapter-background-color);
      color: var(--sc-course-chapter-color);
    }
    .chapter-lessons {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0.5rem;
      margin-left: get-safe-area-inset-left();

      .chapter-lesson {
        @extend .rounded !optional;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color .2s,
                    color .2s;
        border-radius: 0.25rem;
        text-decoration: none;
        color: var(--sc-course-lesson-color);

        display: flex;
        flex-direction: row;
        .lesson-title {
          flex-grow: 1;
        }
        .lesson-completion-icon {
          color: var(--sc-course-lesson-complete-color);
          transition: color .2s;
        }

        &:hover {
          background-color: var(--sc-course-lesson-hover-background-color);
          color: var(--sc-course-lesson-hover-color);
        }
        &.active {
          background-color: var(--sc-course-primary-background-color);
          color: var(--sc-course-primary-color) !important;
          .lesson-completion-icon {
            color: var(--sc-course-primary-color);
          }
        }
      }
    }
  }
}
