.product-page-sticky-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  background-color: white;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);

  padding-top: .75rem;
  padding-bottom: .75rem;

  .btn {
    width: 100%;
  }
}

.product-page-add-to-cart-button {
  padding: .6rem 1.5rem;
}
