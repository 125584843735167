$product-image-placeholder-color: #b4b4b4 !default;
$border-radius: .25rem !default;

.product-image-placeholder-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .product-image-placeholder-content {
    color: $product-image-placeholder-color;
    font-size: 2rem;
  }
}

.product-page-image-container {
  .product-page-image-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem;

    background: white;

    padding: .5rem;

    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    .product-page-image-list-item {
      display: inline-flex;
      justify-content: center;
      align-content: center;

      width: 87px;
      height: 87px;

      border-radius: $border-radius;

      border: 1px solid #CCC; //#E3EBF6;
      padding: 5px;
      background-color: white;
      cursor: pointer;

      transition: border-color .3s ease;

      &.active {
        border: 3px solid $primary;
        padding: 3px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    @media (max-width: 767px) {
      gap: 0.25rem;
      .product-page-image-list-item {
        width: 60px;
        height: 60px;
      }
    }
  }
}
