$course-progress-text-color-0: #dc3545 !default;
$course-progress-text-color-50: #ffc107 !default;
$course-progress-text-color-100: #28a745 !default;

$course-access-text-color-expired: #dc3545 !default;
$course-access-text-color-active: #6c757d !default;

.card-course {
  --sc-course-progress-text-color-0: #{$course-progress-text-color-0};
  --sc-course-progress-text-color-50: #{$course-progress-text-color-50};
  --sc-course-progress-text-color-100: #{$course-progress-text-color-100};

  .progress-text {
    &.progress-text-color-0 {
      color: var(--sc-course-progress-text-color-0);
    }
    &.progress-text-color-50 {
      color: var(--sc-course-progress-text-color-50);
    }
    &.progress-text-color-100 {
      color: var(--sc-course-progress-text-color-100);
    }
  }

  --sc-course-access-text-color-expired: #{$course-access-text-color-expired};
  --sc-course-access-text-color-active: #{$course-access-text-color-active};

  .access-text {
    &.access-text-color-expired {
      color: var(--sc-course-access-text-color-expired);
    }
    &.access-text-color-active {
      color: var(--sc-course-access-text-color-active);
    }
  }
}
